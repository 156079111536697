<template>
  <dl>
    <slot />
  </dl>
</template>

<script>
export default {
  name: 'CSummaryList'
}
</script>
